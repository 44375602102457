<template>
    <b-row>
        <b-col xl="7" lg="7" md="12" sm="12"
            class="pr-xl-2 pr-lg-2 pb-md-2 pr-sm-2"
        >

            <ListOperatingUnitsRegionModal 
                :modalShow="modal.showModal"
                :idRegiao="modal.idRegion"
                :nameRegion="modal.nameRegion"
                @confirmed="closeModal"
            />

            <ErrorNumberOfDoses 
                :modalShow="modalErrorDoses.showModal"
                :typeModal="modalErrorDoses.type"
                @confirmed="closeModal"
            />

            <section class="distribution-doses-regiao">
                <div class="mb-1 d-flex justify-content-end align-items-center">
                    <p class="m-0 mr-1 custom-tooltip" for="">Buscar</p>
                    <b-input-group class="input-group-merge input-width-search">
                        <b-form-input 
                            v-model="filterTable"
                            class="custom-input-search"
                        />
                        <b-input-group-append is-text>
                            <feather-icon
                                icon="SearchIcon"
                                color="#B9B9C3"
                                class="cursor-pointer"
                            />
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <b-table
                    ref="tableDoses"
                    id="table-doses-regiao"
                    responsive
                    :busy.sync="filtering"
                    :stickyColumn="true"
                    :fields="table.fields"
                    :items="table.regioes"
                    :filter="filterTable"
                    no-local-sorting
                    :no-sort-reset="true"
                    tbody-tr-class="custom-center-content"
                    table-class="custom-table"
                >
                    <template #cell(actions)="row">
                        <feather-icon v-if="row.item.tooltip"
                            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                            title="Espaço para reserva de doses a serem distribuídas futuramente."
                            icon="InfoIcon" 
                            size="18"
                            color="#2772C0"
                        />
                        <feather-icon v-else
                            @click="openModalListUos(row.item)"
                            icon="EyeIcon" 
                            class="c-pointer"
                            size="18"
                            color="#2772C0"
                        />
                    </template>
                    <template #cell(descricao)="row">
                        <span class="custom-font-bold">{{ row.item.descricao }}</span>
                    </template>
                    <template #cell(noPromotion)="row">
                        <b-form-input
                            v-model="row.item.noPromotion"
                            @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'noPromotion')"
                            v-mask="'##########'"
                            autocomplete="off"
                            class="custom-input-number-doses"
                            id="no-promotion"
                            @update="() => {
                                CalcTable.sumAllRowTable(row.item);
                                CalcTable.sumAllColumTable(doses, table.regioes);
                                liberaRegiosUnidade()
                            }"
                            placeholder="---"
                        />
                    </template>
                    <template #cell(withPromotion)="row">
                        <b-form-input 
                            v-model="row.item.withPromotion"
                            @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'withPromotion')"
                            v-mask="'##########'"
                            autocomplete="off"
                            class="custom-input-number-doses"
                            id="with-promotion"
                            @update="() => {
                                CalcTable.sumAllRowTable(row.item),
                                CalcTable.sumAllColumTable(doses, table.regioes); 
                                liberaRegiosUnidade() 
                            }"
                            placeholder="---"
                        />
                    </template>
                    <template #cell(totalDoses)="row">
                        <span class="custom-font-bold m-0">{{ row.item.totalDoses ? row.item.totalDoses : '---' }}</span>
                    </template>
                </b-table>
            </section>
        </b-col>
        <b-col lg="4" md="4" sm="2" class="separatorBar ml-2 pl-2 pb-5 pb-md-0 mb-md-4" id="doseSection">
            <DoseSummary 
                :dosesNumber="doses"
                :hasIncentive="hasIncentivePromotion"
            />
        </b-col>
    </b-row>
</template>

<script>
import { BTable, BFormInput,  BRow, BCol, VBTooltip, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';

import ListOperatingUnitsRegionModal from '@/views/components/custom/modals/ListOperatingUnitsRegionModal.vue'
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';
import DoseSummary from '@/views/components/custom/DosesSummary/DosesSummaryregionUnit.vue';
import followScreen from '@/views/components/custom/DosesSummary/followScreen';
import CalcTable from '../../Services/CalculatetableDoses';
import TableFieldControl from '../../Services/TableFieldControl';
import DoseDistributionRegionFormService from '../../Services/DoseDistributionRegionFormService';

import { getInfoUserDepartamento } from "@core/utils/store/getStore";
import { busEvent } from '@/main';

export default {
    components: {
        BTable, BFormInput, BRow, BCol, VBTooltip,
        BInputGroup, BInputGroupAppend,
        ListOperatingUnitsRegionModal, ErrorNumberOfDoses,
        DoseSummary
    },

    props: {
        hasPromotion: {
            required: true,
            type: Boolean,
        },
        incentives: {
            required: true,
            type: Object|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        vaccines: {
            required: true
        },
        editedVaccines: {
            required: true
        },
        idVaccine: {
            required: true
        },
        situationCampaign: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            CalcTable,
            hasIncentivePromotion: this.$props.hasPromotion, 
            selectedIncentives: this.$props.incentives,
            selectedNumberOfDoses: this.$props.numberOfDoses,
            infosVaccineCampaign: this.$props.vaccines,
            selectedVaccine: this.$props.idVaccine,
            situationCampaignSelected: this.$props.situationCampaign,
            selectedVaccinesEdited: this.$props.editedVaccines,
            devideByRegion: !getInfoUserDepartamento('divisao_por_regiao'),
            modal: {
                showModal: false,
                idRegion: null,
                nameRegion: null,
            },
            modalErrorDoses: {
                showModal: false,
                type: 'rascunho-regiao'
            },
            filterTable: null,
            filtering: true,
            doses: {
                totalDosesWithPromotion: 0,
                totalDosesWithoutPromotion: 0,

                reservationWithPromotion: 0,
                reservationWithoutPromotion: 0,

                totalDistributedDosesWithPromotion: 0,
                totalDistributedDosesWithoutPromotion: 0,

                controlDosesWithPromotion: 0,
                controlDosesWithoutPromotion: 0,
            },
            table: {
                fields: [
                    {key: 'actions', label: '', class: 'custom-width-actions-table px-1'},
                    {key: 'descricao', label: 'Região', class: 'pl-1'},
                    {key: 'noPromotion', label: 'Sem fomento'},
                    {key: 'withPromotion', label: 'Com fomento'},
                    {key: 'totalDoses', label: 'Total', class: 'text-center'},
                ],
                regioes: [
                    {
                        descricao: 'Reserva do Dep. Regional',
                        idRegiao: null,
                        noPromotion: null,
                        withPromotion: null,
                        totalDoses: null,
                        tooltip: true,
                    }
                ],
            },
        }
    },

    mounted() {
        this.loadRegioes();
        window.addEventListener('scroll', followScreen.verifyScroll());
        this.verifyIfDepartamentHasUnits();

        if(this.$props.numberOfDoses) {
            this.thingsToDoWhenChangeNumberOfDoses(this.$props.numberOfDoses);
        }

        this.$emit('regionsDistributeUnidade', [[], true, false]);

        busEvent.$on('saveCampanha', this.saveCampaignListener);
        busEvent.$on('checkDistributionRegionLink', this.checIfVaccineHasDistributionRegion);
        busEvent.$on('checkHasDistributionRegion', this.checkIfHasDistribution);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', followScreen.verifyScroll())
        busEvent.$off('saveCampanha', this.saveCampaignListener);
        busEvent.$off('checkDistributionRegionLink', this.checIfVaccineHasDistributionRegion);
        busEvent.$off('checkHasDistributionRegion', this.checkIfHasDistribution);
    },

    methods: {
        saveCampaignListener() {
            const reservation = DoseDistributionRegionFormService.prepareDataToSaveReservation(
                this.doses, this.selectedNumberOfDoses
            );
            const DistributionRegion = DoseDistributionRegionFormService.prepareDataSaveDoseDistribution(
                this.table.regioes, this.selectedIncentives
            );

            busEvent.$emit('distributionRegionPrepare', { 
                idVaccine: this.selectedVaccine, 
                reservation, DistributionRegion 
            });
        },

        openModalListUos(region) {
            this.modal.idRegion = region.idRegiao;
            this.modal.nameRegion = region.descricao;
            this.modal.showModal = true;
        },

        closeModal() {
            this.modal.showModal = false;
            this.modalErrorDoses.showModal = false;
        },
        async loadRegioes() {
            const { data } = await this.$http.get(this.$api.regiao(), { params: {  ativo: true } });
            if(data.length){
                data.map(regiao => {
                  if(regiao.unidade.length > 0){
                    this.table.regioes.push({
                        descricao: regiao.descricao,
                        idRegiao: regiao.id_regiao,
                        noPromotion: null,
                        withPromotion: null,
                        totalDoses: null
                    })
                  }
                })
            }else{
                busEvent.$emit('noRegionDistribution');
            }

            this.filtering = false;
            this.fillValuesRegions();
        },

        thingsToDoWhenChangeNumberOfDoses(numberOfDoses) {
            this.fillDosesDistribution(numberOfDoses);
            TableFieldControl.removePromotion(this.hasIncentivePromotion, this.doses, this.table);
            TableFieldControl.removeWithoutPromotion(this.doses, this.table);
            CalcTable.sumAllColumTable(this.doses, this.table.regioes);
            this.$refs.tableDoses.refresh();
        },

        fillValuesRegions() {
            if(this.devideByRegion && this.selectedVaccinesEdited.length > this.infosVaccineCampaign.length) {
                this.liberaRegiosUnidade(false);
                return;
            }

            const vaccineDistribution = this.infosVaccineCampaign.filter(vaccine => vaccine.id_vacina == this.selectedVaccine)[0];
            if(!vaccineDistribution) { return; }

            if(this.hasIncentivePromotion) {
                const estimativaPromotion = vaccineDistribution.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao != 'Normal')[0];

                if(estimativaPromotion) {
                    this.fillDosesAmount(estimativaPromotion, 'withPromotion');
                }
            }

            const estimativaNoPromotion = vaccineDistribution.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao == 'Normal')[0];
            if(estimativaNoPromotion) {
                this.fillDosesAmount(estimativaNoPromotion, 'noPromotion');
            }

            CalcTable.sumAllColumTable(this.doses, this.table.regioes);
            this.liberaRegiosUnidade(false);
        },

        fillDosesAmount(estimativas, typePromotion) {
            this.table.regioes.map(region => {
                if (region.idRegiao) {
                    const regions = estimativas.campanha_regiao
                        .filter(regionCampaign => regionCampaign.id_regiao == region.idRegiao)[0];
                    if(regions) {
                        region[typePromotion] = regions.quantidade;
                    }
                } else {
                    region[typePromotion] = estimativas.reserva ? estimativas.reserva : null;
                }
                CalcTable.sumAllRowTable(region);
            });
        },

        fillDosesDistribution(doses) {
            this.doses.totalDosesWithPromotion = 0;
            this.doses.controlDosesWithPromotion = 0;
            this.doses.totalDosesWithoutPromotion = 0;
            this.doses.controlDosesWithoutPromotion = 0

            this.doses.totalDosesWithPromotion = doses.numberOfDosesWithPromotion.quantity ? parseInt(doses.numberOfDosesWithPromotion.quantity) : 0;
            this.doses.controlDosesWithPromotion = doses.numberOfDosesWithPromotion.quantity ? parseInt(doses.numberOfDosesWithPromotion.quantity) : 0;
            this.doses.totalDosesWithoutPromotion = doses.numberOfDosesWithoutPromotion.quantity ? parseInt(doses.numberOfDosesWithoutPromotion.quantity) : 0;
            this.doses.controlDosesWithoutPromotion = doses.numberOfDosesWithoutPromotion.quantity ? parseInt(doses.numberOfDosesWithoutPromotion.quantity) : 0;
        },

        liberaRegiosUnidade(checkError = true) {
            let regioesDistributeUnidade = [];

            const regionWithoutReservation = this.table.regioes.filter(region => region.idRegiao);

            if(this.devideByRegion && this.selectedVaccinesEdited.length > this.infosVaccineCampaign.length) {
                regionWithoutReservation.map(region => {
                    regioesDistributeUnidade.push(region);
                });

                this.$emit('regionsDistributeUnidade', [regioesDistributeUnidade, false]);

                return;
            }

            regionWithoutReservation.map(region => {
                if(region.noPromotion || region.withPromotion) {

                    if(this.devideByRegion) {
                        region.withPromotion = this.selectedNumberOfDoses.numberOfDosesWithPromotion.quantity;
                        region.noPromotion = this.selectedNumberOfDoses.numberOfDosesWithoutPromotion.quantity;
                        region.totalDoses = 
                            this.selectedNumberOfDoses.numberOfDosesWithPromotion.quantity + 
                            this.selectedNumberOfDoses.numberOfDosesWithoutPromotion.quantity;
                    }

                    regioesDistributeUnidade.push(region);
                }
            });

            let error = false;

            if(!this.devideByRegion) {
                error = this.validadeNumberOfDosesDistributed(false);
                const errorDoses = this.validateHasDosesAvailable();

                if(checkError && errorDoses) {
                    busEvent.$emit('checkErrorDistributionUnit', this.selectedVaccine);
                }
            }

            this.$emit('regionsDistributeUnidade', [regioesDistributeUnidade, error]);
        },

        validateHasDosesAvailable() {
            let error = false;

            if(this.doses.controlDosesWithoutPromotion == 0 && this.doses.controlDosesWithPromotion == 0 && 
            (this.selectedNumberOfDoses.numberOfDosesWithPromotion.quantity > 0 || this.selectedNumberOfDoses.numberOfDosesWithoutPromotion.quantity > 0))
            {
                error = true;
            }

            busEvent.$emit('canSaveScheduleDistributionRegion', [error, this.selectedVaccine]);

            if(this.situationCampaignSelected != 'Rascunho') {
                this.emitErrorDose(error);
                busEvent.$emit('blockButtonStep', {
                    block: !error, 
                    idVaccine: this.selectedVaccine
                });

                return error;
            } else {
                let errorRascunho = false;

                if(this.doses.controlDosesWithoutPromotion < 0 || this.doses.controlDosesWithPromotion < 0) {
                    errorRascunho = true;
                }

                this.emitErrorDose(!errorRascunho);
                return !errorRascunho;
            }
        },

        validadeNumberOfDosesDistributed(noOpenModal = true) {
            const regionWithoutReservation = this.table.regioes.filter(region => region.idRegiao);

            const totalDosesDistributedNoPromotion = regionWithoutReservation.reduce(
                (accumulator, region) => accumulator + (region.noPromotion ? parseInt(region.noPromotion) : 0)
            , 0);

            const totalDosesDistributedWithPromotion = regionWithoutReservation.reduce(
                (accumulator, region) => accumulator + (region.withPromotion ? parseInt(region.withPromotion) : 0)
            , 0);

            if(totalDosesDistributedNoPromotion == 0 && totalDosesDistributedWithPromotion == 0) {
                return false;
            }

            const totalDosesNoPromotion = this.doses.totalDosesWithoutPromotion - this.doses.reservationWithoutPromotion;
            const totalDosesWithPromotion = this.doses.totalDosesWithPromotion - this.doses.reservationWithPromotion;

            if(totalDosesDistributedNoPromotion == 0 && totalDosesDistributedWithPromotion == 0) {
                return false;
            }

            if(
                (totalDosesDistributedNoPromotion > totalDosesNoPromotion) || 
                (totalDosesDistributedWithPromotion > totalDosesWithPromotion)
            ) {
                if(noOpenModal) {
                    this.modalErrorDoses.showModal = true;
                }
                return true;
            }

            return false;
        },

        emitErrorDose(error) {
            busEvent.$emit('errorDoseDistribution', {
                error: !error, 
                vaccineId: this.selectedVaccine,
                type: 'region'
            });
        },

        verifyIfDepartamentHasUnits() {
            this.$http.get(this.$api.unidade(), { 
                params: { ativo: true } 
            }).then(({ data }) => {
                if(!data.length){
                    busEvent.$emit('noUnitDistribution');
                }
            });
        },

        checIfVaccineHasDistributionRegion(vaccineId) {
            if(this.selectedVaccine == vaccineId) {
                const reservation = DoseDistributionRegionFormService.prepareDataToSaveReservation(
                    this.doses, this.selectedNumberOfDoses
                );
                const distribution = DoseDistributionRegionFormService.prepareDataSaveDoseDistribution(
                    this.table.regioes, this.selectedIncentives
                );

                if((distribution.length > 0 || reservation) && !this.devideByRegion) {
                    busEvent.$emit('distributionRegionHasDoses', true);
                    return;
                }
                busEvent.$emit('distributionRegionHasDoses', false);
            }
        },

        checkIfHasDistribution(vaccineId) {
            if(this.selectedVaccine == vaccineId) {
                const reservation = DoseDistributionRegionFormService.prepareDataToSaveReservation(
                    this.doses, this.selectedNumberOfDoses
                );
                const distribution = DoseDistributionRegionFormService.prepareDataSaveDoseDistribution(
                    this.table.regioes, this.selectedIncentives
                );

                if(distribution.length > 0 || reservation) {
                    busEvent.$emit('hasDistribution', {hasDistribution: true, vaccineId});
                    return;
                }
                busEvent.$emit('hasDistribution', {hasDistribution: false, vaccineId});
            }
        },
        validateReservation(noOpenModal = true) {
          if(this.doses.reservationWithoutPromotion > this.doses.totalDosesWithoutPromotion ||
              this.doses.reservationWithPromotion > this.doses.totalDosesWithPromotion
          ){
            if(noOpenModal) {
              this.modalErrorDoses.showModal = true;
            }
            this.$emit('errorNumberDoses', this.selectedIdVaccine);

            return true;
          }
          busEvent.$emit('hasNoErrorsDistribution');
        },
    },

    watch: {
        hasPromotion(value) {
            this.hasIncentivePromotion = value;
            TableFieldControl.removePromotion(value, this.doses, this.table);
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;
        },

        numberOfDoses: {
            handler (newValue) {
                if(!this.devideByRegion) {
                    setTimeout(() => { this.validateHasDosesAvailable() }, 20);
                }
                this.thingsToDoWhenChangeNumberOfDoses(newValue);
                this.selectedNumberOfDoses = newValue;
            },
            deep: true,
        },

        vaccines(vaccines) {
            this.infosVaccineCampaign = vaccines;
        },

        editedVaccines(vaccines) {
            this.selectedVaccinesEdited = vaccines;
            this.validateHasDosesAvailable();
        },

        idVaccine(idVaccine) {
            this.selectedVaccine = idVaccine;
        },

        doses: {
          handler () {
            if(this.devideByRegion && this.table.regioes[1]){
              this.table.regioes[1].noPromotion = this.doses.totalDosesWithoutPromotion;
              this.table.regioes[1].withPromotion = this.doses.totalDosesWithPromotion;
              this.liberaRegiosUnidade();
            }

            this.validateHasDosesAvailable();

            let hasError = this.validadeNumberOfDosesDistributed(false) || this.validateReservation(false) ? true : false;
            this.$emit('validDoseDistribution', !hasError);
          },
          deep: true,
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.custom-input-number-doses {
    border-color: $custom-blue;
    background: rgba(39, 114, 192, 0.04);
    max-width: 108px;
    height: 34px;
    text-align: center;
}

.custom-input-number-doses::placeholder {
    color: #5E5873;
}

.custom-table thead tr th{
    background: $custom-blue !important;
    color: white;
}

.custom-table {
    min-width: 400px !important;
}

.custom-width-actions-table{
    min-width: 30px;
}

.custom-font-bold {
    font-weight: 800;
}

.custom-tooltip {
    font-size: 14px;
}

.c-pointer {
    cursor: pointer;
}

.margin-text-custom {
    margin: 0;
    margin-top: 0.4rem;
}

.custom-input-search {
    height: 30px;
}

.input-width-search {
    width: 305px;
}

.separatorBar {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}
</style>